/**
 * Given function will be executed when DOM is ready.
 * If DOM is already ready, function will be executed immediately.
 * @param {Function} fn - Function to be executed.
 * @param {object} context - Context to be used when executing function.
 * @returns {void}
 */
export function ready (fn, context) {
  context = context || document
  // http://youmightnotneedjquery.com/#ready
  if (context.readyState !== 'loading') {
    fn()
  } else {
    context.addEventListener('DOMContentLoaded', fn)
  }
}

/**
 * Trigger event on the element.
 * @param {string} eventName - Name of event.
 * @param {object} element - Element to trigger event on.
 * @param {object} data - Data to pass to event.
 */
export function trigger (eventName, element, data) {
  element = element || document
  data = data || {}
  element.dispatchEvent(new Event(eventName, data))
}

/**
 * Given function will be executed when DOM is ready and the element exists.
 * @param {Function} fn - Function to be executed.
 * @param {string} query - Query selector to find element.
 * @returns {void}
 */
export function mount (fn, query) {
  ready(() => {
    document.querySelectorAll(query).forEach(element => fn(element))
  })
}

/**
 * Debounce both sync and async functions.
 * @param {Function} func - Function to be executed.
 * @param {number} wait - Time to wait before executing function in milliseconds.
 * @returns {Function} - Debounced function.
 */
export function debounce (func, wait = 300) {
  let timeout
  if (func[Symbol.toStringTag] === 'AsyncFunction') {
    return function (...args) {
      clearTimeout(timeout)
      return new Promise((resolve, reject) => {
        timeout = setTimeout(() => {
          Promise.resolve(func.apply(this, [...args])).then(resolve).catch(reject)
        }, wait)
      })
    }
  } else {
    return function (...args) {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        timeout = null
        func.apply(this, [...args])
      }, wait)
    }
  }
}

export function getFromLocalStorage (key, defaultValue) {
  if (localStorage.getItem(key)) {
    try {
      return JSON.parse(localStorage.getItem(key))
    } catch (e) {
      localStorage.removeItem(key)
    }
  }
  return defaultValue
}

export function setToLocalStorage (key, value) {
  localStorage.setItem(key, JSON.stringify(value))
}

/**
 * Automatically submit form when value of input field changes.
 * @param {string} formName - Name of the form.
 * @returns {void}
 */
export function autoSubmitForm (formName) {
  for (const element of document.forms[formName].elements) {
    element.addEventListener('change', () => {
      document.forms[formName].submit()
    })
  }
}

/**
 * Bind value of input field to property of object.
 * @param {object} self - Object to bind property to.
 * @param {string} property - Name of property to bind.
 * @returns {callback} - Callback function to be used as event listener.
 */
export function bindValue (self, property) {
  return (e) => {
    self[property] = e.target.value
  }
}

/**
 * Parse localized string to number handling decimal and thousands separators.
 * https://observablehq.com/@mbostock/localized-number-parsing#NumberParser
 */
export class NumberParser {
  constructor (locale) {
    const parts = new Intl.NumberFormat(locale).formatToParts(12345.6)
    const numerals = [...new Intl.NumberFormat(locale, { useGrouping: false }).format(9876543210)].reverse()
    const index = new Map(numerals.map((d, i) => [d, i]))
    this._group = new RegExp(`[${parts.find(d => d.type === 'group').value}]`, 'g')
    this._decimal = new RegExp(`[${parts.find(d => d.type === 'decimal').value}]`)
    this._numeral = new RegExp(`[${numerals.join('')}]`, 'g')
    this._index = d => index.get(d)
  }

  parse (string) {
    return parseFloat(string.trim()
      .replace(this._group, '')
      .replace(this._decimal, '.')
      .replace(this._numeral, this._index))
  }
}

/**
 * Turn value into displayable percentage value.
 * @param {string} value - Value to convert to percentage.
 * @param {number} precision - Number of decimal places.
 * @returns {string} - Percentage value.
 */
export function toPercentage (value, precision) {
  return Number(value).toLocaleString(window.language, { style: 'percent', minimumFractionDigits: precision })
}

/**
 * Localize value.
 * @param {Date | number} value - Value to localize.
 * @returns {string} - Localized value.
 */
export function localize (value) {
  if (value === null || value === undefined) {
    return ''
  } else if (typeof value === 'number') {
    return value.toLocaleString(window.language)
  } else if (value instanceof Date) {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const valueDay = new Date(value.getFullYear(), value.getMonth(), value.getDate())

    let options

    if (valueDay.valueOf() === today.valueOf()) {
      // same day
      options = { hour: 'numeric', minute: 'numeric' }
    } else if (value > now && value - now < 1000 * 60 * 60 * 24 * 7) {
      // same week
      options = { weekday: 'short', hour: 'numeric', minute: 'numeric' }
    } else {
      options = { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' }
    }
    return `${value.toLocaleString(window.language, options)}${window.language === 'de' ? ' Uhr' : ''}`
  } else {
    return value
  }
}

export const gtag = function () { (window.dataLayer || []).push(arguments) }

/**
 * Observe first intersection of element with viewport and disconnect after first intersection.
 * @param {Function} callback - Function to be executed when element is in viewport.
 * @param {HTMLElement} element - Element to observe.
 * @param {object} options - Options to pass to IntersectionObserver.
 * @returns {IntersectionObserver} - IntersectionObserver instance.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
 */
export function observeFirstViewportIntersection (callback, element, options) {
  const observer = new IntersectionObserver(function (entries, observer) {
    entries.forEach(function (entry) {
      if (entry.isIntersecting) {
        callback()
        observer.disconnect()
      }
    })
  },
  {
    ...options,
    root: null, // viewport
    threshold: 1.0
  }
  )
  observer.observe(element)
  return observer
}

/**
 * Capitalize first letter of string.
 * @param {string} value - String to capitalize.
 * @returns {string} - Sentence case string.
 */
export function capFirst (value) {
  if (typeof value !== 'string') {
    return value
  }
  return value.charAt(0).toUpperCase() + value.slice(1)
}

/**
 * Generate random integer between min and max.
 * @param {number} min - Minimum value.
 * @param {number} max - Maximum value.
 * @returns {number} - Random integer.
 */
export function randomInt (min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

/**
 * Return singular or plural based on count.
 * @param {number} count - Count to base decision on.
 * @param {string} singular - Singular form of word.
 * @param {string} plural - Plural form of word.
 * @returns {string} - Singular or plural form of word.
 */
export function getByCount (count, singular, plural) {
  return count === 1 ? singular : plural
}

/**
 * Truncate text to given length.
 * @param {string} text - Text to truncate.
 * @param {number} length - Maximum length of text.
 * @returns {string} - Truncated text.
 */
export function truncate (text, length) {
  return text.length > length ? text.slice(0, length - 1) + '…' : text
}

/**
 * Check if breakpoint is reached
 * @param {string} breakpoint - Breakpoint to check (mobile/desktop).
 * @returns {boolean} - True if mobile layout is required.
 */
export function isBreakpoint (breakpoint) {
  switch (breakpoint) {
    case 'mobile':
      return window.innerWidth < 1024
    case 'desktop':
      return window.innerWidth >= 1024
    default:
      throw new Error(`Unknown breakpoint: ${breakpoint}`)
  }
}

/**
 * A wrapper for the html [`<picture>`](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/picture) element. Designed to work with the default
 * serialized image from [django-pictures](https://pypi.org/project/django-pictures/). To be used withing a lit component.
 * @param {object} picture - The JSON serialized picture object.
 * @param {string} altText - The alt text for the img tag.
 * @param {string} width - The width of the image for the img tag.
 * @param {string} height - The height of the image for the img tag.
 * @param {string} ratio - The aspect ratio of the picture element.
 * @param {object} pictureStyle - The styles for the picture tag as a object.
 * @param {string} pictureClass - The classes for the picture tag.
 * @param {object} imgStyle - The styles for the img tag as a object.
 * @param {string} imgClass - The classes for the img tag.
 * @returns {string} - The picture element as a string.
 */
export function picture ({
  picture,
  altText,
  width,
  height,
  ratio,
  pictureStyle,
  pictureClass,
  imgStyle = 'object-fit: cover; width: 100%; height: 100%;',
  imgClass
}) {
  const sources = Object.entries(picture.ratios[ratio].sources).map(source => {
    return `
            <source type="${source[0]}"
                    srcset="${getPictureSource(source[1])}"
                    sizes="${picture.ratios[ratio].media}">
          `
  }).join('')
  return `
    <picture ${pictureClass ? `class="${pictureClass}"` : ''}
             ${pictureStyle ? `style="${pictureStyle}"` : ''}>
      ${sources}
      <img ${imgClass ? `class="${imgClass}"` : ''}
           ${imgStyle ? `style="${imgStyle}"` : ''}
           ${altText ? `alt="${altText}"` : ''}
           ${width ? `width="${width}"` : ''}
           ${height ? `height="${height}"` : ''}
           src="${picture.url}"
           loading="lazy"/>
    </picture>
  `
}

export function getPictureSource (picture) {
  let source = ''
  if (picture !== null) {
    for (const [px, url] of Object.entries(picture)) {
      source += `${url} ${px}w, `
    }
  }
  return source
}
