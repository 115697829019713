import '#js/components/snackbar'
import { ready } from '#js/components/utils'

/* Update button state based on form input fields */
export function updateButtonState () {
  const emailInput = document.getElementById('emailInput')
  const phoneInput = document.getElementById('phoneInput')
  const submitButton = document.getElementById('submitButton')
  submitButton.disabled = emailInput.value.trim() === '' && phoneInput.value.trim() === ''
}

/* Play background video dependent on screen size */
export function playBackgroundVideo () {
  const video = document.getElementById('backgroundVideo')
  if (video && Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) > 768) {
    const promise = video.play()
    if (promise !== undefined) {
      promise.then(() => {
        // background video is playing
      }).catch(ignore => {
        // background video is not supported or blocked
      })
    }
  }
}

/* Attach input listeners to email and phone inputs */
export function attachInputListeners () {
  const emailInput = document.getElementById('emailInput')
  const phoneInput = document.getElementById('phoneInput');
  [emailInput, phoneInput].forEach(input => {
    input.addEventListener('input', updateButtonState)
  })
}

/* Prevent empty form submission */
export function preventEmptySubmit () {
  attachInputListeners()
  updateButtonState()
}

ready(function () {
  playBackgroundVideo()
  preventEmptySubmit()
})
